import styled from '@emotion/styled'
import React, { FC } from 'react'

const ContainerStyled = styled.div(({ theme }) => ({
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '1280px',
    padding: '0px 24px',
    boxSizing: 'border-box',

    [theme.breakpoints.down(theme.breakpoints.lg)]: {
        padding: theme.spacing(0, 2),
        width: '100%',
    },

    /*'@media (max-width: 768px)': {
        padding: '0px 24px',
    },
    '@media (max-width: 375px)': {
        padding: '0px 16px',
    },
    '@media (max-width: 1280px)': {
        width: '100%',
    },*/
}))

const Container: FC = ({ children }) => <ContainerStyled>{children}</ContainerStyled>

export default Container
