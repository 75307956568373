import { jsx } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'

import Container from '../../../components/Container'
import { ServiceCategoryTemplateQuery_strapiServiceCategory } from '../__generated__/ServiceCategoryTemplateQuery'
import CategItem from './CategItem'
import { ContentWrapperStyled, SectionStyled } from './_styles'

type IProps = {
    categories: ServiceCategoryTemplateQuery_strapiServiceCategory[]
    personaSlug?: string | null
}

const ServiceCategSection: FC<IProps> = ({ categories, personaSlug }) => {
    return (
        <SectionStyled>
            <Container>
                <ContentWrapperStyled>
                    {categories &&
                        categories.map((categItem, index) => (
                            <CategItem
                                key={categItem.strapiId}
                                categoryName={categItem.categoryName ?? ''}
                                categoryName_mg={categItem.categoryName_mg ?? ''}
                                categorySlug={categItem.categorySlug ?? ''}
                                categoryDescription={categItem.categoryDescription ?? ''}
                                categoryDescription_mg={categItem.categoryDescription_mg ?? ''}
                                serviceCount={categItem.services?.length}
                                personaSlug={personaSlug}
                                index={index}
                            />
                        ))}
                </ContentWrapperStyled>
            </Container>
        </SectionStyled>
    )
}

export default ServiceCategSection
