import styled from '@emotion/styled'

export const SectionStyled = styled.section((props) => {
    return {
        /*backgroundColor: props.theme.sections.servicePresentation.backgroundLight */
    }
})

export const ContentWrapperStyled = styled.div((props) => {
    return {
        padding: props.theme.spacing(0, 0, 12, 0),
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginLeft: props.theme.spacing(-1),
        marginRight: props.theme.spacing(-1),
        [props.theme.breakpoints.down(props.theme.breakpoints.md)]: {
            padding: props.theme.spacing(0, 0, 4, 0),
        },
    }
})
